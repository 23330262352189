import '../css/normalize.css';
import '../css/fonts.css';
import '../css/icons.css';
import '../css/App.css';
import Layout from './Layout';

function App() {
  return (
    <div className="App">
      <Layout />
    </div>
  );
}

export default App;
