import React, { Component } from 'react';
import ReactGA from 'react-ga';
import LinkButton from './LinkButton';
import Logo from '../images/logo_esperanzas.png';
import data from '../constants/links.json';

export default class Layout extends Component {
  componentDidMount() {
    ReactGA.initialize(window.gaId);
    ReactGA.pageview('/');
  }

  render() {
    return (
      <div className="container">
        <div className="container-max-width">
          <header>
            <img src={Logo} alt="Esperanza's Tour" />
          </header>
          {
            data.linkButtons.map((button, index) => 
            <LinkButton
              key={index}
              {...button}
            />)
          }
        </div>
      </div>
    )
  }
}
